import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_KeepAlive, {
        exclude: ['Login']
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))];
    }),
    _: 1
  });
}